import styled from "styled-components";
import { Colors } from "../Styles/ColorPalette";

interface PrimaryButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  margin?: string;
}

const StyledButton = styled.button<{ margin?: string }>`
  background-color: ${Colors.button.primary.background.regular};
  color: ${Colors.button.primary.text.regular};
  font-size: 0.9rem;    
  font-weight: bold;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  margin: ${props => props.margin || '0'};
  &:disabled {
    background-color: ${Colors.button.primary.background.disabled};
    color: ${Colors.button.primary.text.disabled};
    cursor: not-allowed;
  }
`;

export const PrimaryButton = ({ onClick, children, disabled, margin }: PrimaryButtonProps) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} style={{ margin }}>
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;