import { Chat } from "../../Domain/API";
import React, { useState } from "react";
import { useUpdateChatActive } from "../../Domain/chats";
import { formatDistanceToNow } from "date-fns";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../Styles/ColorPalette";
import StatusButton from "../SharedComponents/StatusButton";
import Tag from "../SharedComponents/Tag";

export const ChatListItem = (chat: Chat) => {
  const { chatId, username = '', fullName = '', lastMessageTime, chatStatus, total_tokens, tags = [] } = chat;
  const [active, setActive] = useState(chat.active);
  const { mutate: updateChatActive, error, isPending } = useUpdateChatActive();
  const selectedChatId = useParams().chatId as string;
  const isSelected = selectedChatId === chatId;

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newActive = e.target.checked;
    updateChatActive(
      {
        chatId,
        active: newActive,
      },
      {
        onSuccess: () => {
          setActive(newActive);
        },
      }
    );
  };

  const date = lastMessageTime && new Date(lastMessageTime);
  const relativeTime = date && formatDistanceToNow(date, { addSuffix: true });

  const inputTokenPrice = 0.01; // per 1000 tokens
  const outputTokenPrice = 0.03; // per 1000 tokens
  const averageTokenPrice = (inputTokenPrice + outputTokenPrice) / 2 / 1000;
  const price = total_tokens ? total_tokens * averageTokenPrice : 0;
  const roundedPrice = parseFloat(price.toFixed(2));

  return (
    <ChatItemContainer $isSelected={isSelected}>
      <ChatLink to={`/chats/${chatId}`}>
        <ChatDetailsHeader>
          <UserName $isSelected={isSelected}>{fullName}</UserName>
          <TimeStamp $isSelected={isSelected}>{relativeTime}</TimeStamp>
        </ChatDetailsHeader>
      
        <ChatDetails>
          <ChatDetailsList>
            <ChatDetailItem $isSelected={isSelected}>chatId: <DetailValue>{chatId}</DetailValue></ChatDetailItem>
            <ChatDetailItem $isSelected={isSelected}>username: <DetailValue>{username}</DetailValue></ChatDetailItem>
            {chatStatus && <ChatDetailItem $isSelected={isSelected}>status: <DetailValue>{chatStatus}</DetailValue></ChatDetailItem>}
            {total_tokens && (
              <ChatDetailItem $isSelected={isSelected}>
                tokens: <DetailValue>{total_tokens} (${roundedPrice})</DetailValue>
              </ChatDetailItem>
            )}
            {isPending && <LoadingMessage>updating the chat...</LoadingMessage>}
            {error && <ErrorMessage>Error updating the chat {error?.message}</ErrorMessage>}
          </ChatDetailsList>
          {!isPending && (
            <ActiveStatusContainer $isSelected={isSelected}>
              <StatusButton isSelected={isSelected} isActive={active}>
                <StatusCheckbox
                  type="checkbox"
                  id={`switch-${chatId}`}
                  checked={active}
                  onChange={handleActiveChange}
                />
                <StatusLabel htmlFor={`switch-${chatId}`}>
                  {active ? 'Active' : 'Inactive'}
                </StatusLabel>
              </StatusButton>
            </ActiveStatusContainer>
          )}
        </ChatDetails>

        <ChatDetailsTags>
          {tags.map((tag) => (
            <Tag 
              key={tag}
              variant={isSelected ? "inverted" : "default"}
            >{tag}</Tag>
          ))}
          
        </ChatDetailsTags>
      </ChatLink>
    </ChatItemContainer>
  );
}

const ChatItemContainer = styled.div<{ $isSelected: boolean }>`
  padding: 16px;
  background-color: ${props => (props.$isSelected ? Colors.background.selection : Colors.background.primary)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid ${Colors.border.primary};
`;

const ChatLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
`;

const UserName = styled.div<{ $isSelected: boolean }>`
  font-size: 1.0rem;
  font-weight: 600;
  margin-bottom: 4px;
  color: ${props => (props.$isSelected ? Colors.text.primary.selected : Colors.text.primary.regular)};
`;

const TimeStamp = styled.div<{ $isSelected: boolean }>`
  font-size: 0.83rem;
  color: ${props => (props.$isSelected ? Colors.text.secondary.selected : Colors.text.secondary.regular)};
`;

const ChatDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ChatDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  color: #374151;
`;

const ChatDetailsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatDetailItem = styled.div<{ $isSelected: boolean }>`
  font-size: 0.8rem;
  color: ${props => (props.$isSelected ? Colors.text.secondary.selected : Colors.text.secondary.regular)};
`;

const ChatDetailsTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const DetailValue = styled.span`
  font-weight: 500;
`;

const LoadingMessage = styled.div`
  color: #3b82f6;
`;

const ErrorMessage = styled.div`
  color: #ef4444;
`;

const ActiveStatusContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  margin-top: 4px;
  justify-content: flex-end;
`;

const StatusCheckbox = styled.input`
  height: 18px;
  width: 18px;
  margin-right: 5px;
`;

const StatusLabel = styled.label`
  color: inherit;
`;

export default ChatListItem;
