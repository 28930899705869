import styled from "styled-components";
import { Colors } from "../Styles/ColorPalette";

interface StatusButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
}

const StyledButton = styled.button<{ $isActive: boolean, $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  color: ${
    props => props.$isSelected 
    ? Colors.button.secondary.text.selected
    : props.$isActive ? Colors.button.secondary.text.regular : Colors.text.secondary.regular
  };
  font-size: 0.875rem;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid ${
    props => props.$isSelected 
    ? Colors.button.secondary.border.selected
    : props.$isActive ? Colors.button.secondary.border.regular : Colors.border.primary
  };
`;

export const StatusButton = ({ onClick, children, disabled, isActive, isSelected }: StatusButtonProps) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} $isActive={isActive} $isSelected={isSelected}>
      {children}
    </StyledButton>
  );
};

export default StatusButton;