import { useChatMessages } from "../../Domain/chats";
import { ChatMessage } from "../../Domain/API";
import styled from "styled-components";
import DateLabel from "../SharedComponents/DateLabel";
import MessagePhoto from "./MessagePhoto";
// import { Avatar } from '@mui/material';
// import { Person } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { Colors } from "../Styles/ColorPalette";
import { Banner } from "../SharedComponents/Banner";

const ChatMessages = ({ chatId }: { chatId: string }) => {
  const { data: messages, isLoading, error } = useChatMessages(chatId);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <ChatMessagesContainer>
      {/*<MessagesTitle>Messages</MessagesTitle>*/}
      {isLoading && <Banner variant="warning">Loading...</Banner>}
      {error && <Banner variant="error">{error?.message}</Banner>}
      {messages && messages.map((message: ChatMessage) => {
        const isAI = message.username === 'AI';
        const isAdmin = message.username === 'admin';

        return (
          <MessageBubble key={message.timestamp} $isAI={isAI} $isAdmin={isAdmin}>
            <MessageHeader>
              {isAdmin
                ? <AdminTitle>Volunteer</AdminTitle>
                : <>
                  {isAI && <AISenderIcon src="/schmidt.jpeg"/>}
                  {/*{!isAI && <UserSenderIconAvatar><Person/></UserSenderIconAvatar>}*/}

                  <MessageSender>{message.firstNameLastName} {message.username ? `(${message.username})` : ''}</MessageSender>
                </>}
            </MessageHeader>
            <MessagePhoto src={message.photoUrl}/>
            <MessageContent>
              {message.message.split('\n').map((line, i) => <div key={i}>{line}</div>)}
            </MessageContent>
            <MessageFooter>
              {isAI && <MessageTokens>Tokens: {message.total_tokens}</MessageTokens>}
              <DateLabel timestamp={message.timestamp}/>
            </MessageFooter>
          </MessageBubble>
        );
      })}
      <div ref={messagesEndRef}/>
    </ChatMessagesContainer>
  );
}

export default ChatMessages;

const MessageHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const AISenderIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
`;

const AdminTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #3b82f6;
`;

// const UserSenderIconAvatar = styled(Avatar)`
//   width: 30px;
//   height: 30px;
//   margin-right: 8px;
// `;

const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const MessagesTitle = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 16px;
  border-bottom: 1px solid #d1d5db;
`;

const MessageBubble = styled.div<{ $isAI: boolean, $isAdmin: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px;
  margin-left: ${props => props.$isAI ?  '40%' : '12px'};
  margin-right: ${props => props.$isAI ? '20px' : '40%'};
  border-radius: 8px;
  background-color: ${props =>
  props.$isAdmin 
    ? Colors.background.chatMessage.agent
    : props.$isAI 
      ? Colors.background.chatMessage.ai 
      : Colors.background.chatMessage.user};
  align-self: ${props => props.$isAI ? 'flex-end' : 'flex-start'};
`;

const MessageSender = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`;

const MessageContent = styled.div`
  color: #374151;
  margin-top: 8px;
  font-size: 0.9rem;
`;

const MessageTokens = styled.div`
  margin-top: 8px;
`;
const MessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6b7280;
  font-size: 0.875rem;
`;
