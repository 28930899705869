import { useParams } from "react-router-dom";
import { useAllUniqueTags, useChat, useUpdateChatTags } from "../../Domain/chats";
import styled from "styled-components";
import React, { useState } from "react";
import ChatMessages from "./ChatMessages";
import DateLabel from "../SharedComponents/DateLabel";
import ChatMessageInput from "./ChatMessageInput";
import { Colors } from "../Styles/ColorPalette";
import { Tag } from "../SharedComponents/Tag";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import PrimaryButton from "../SharedComponents/PrimaryButton";
import { TagModal } from './ChatDetailsAddTagModal';

export const ChatDetails = () => {
  const chatId = useParams().chatId as string;
  const { data: chat, isLoading, error } = useChat(chatId);
  const { mutate: updateChatTags } = useUpdateChatTags();

  // Tags handling
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const handleTagChange = (tag: string) => {
    const currentTags = chat?.tags || [];
    if (currentTags.includes(tag)) { return; }

    updateChatTags(
      { chatId, tags: [...currentTags, tag] },
      { onSuccess: () => console.log('tag added') }
    );
  };
  const handleRemoveTag = (tag: string) => {
    const currentTags = chat?.tags || [];
    const newTags = currentTags.filter(t => t !== tag);
    
    updateChatTags(
      { chatId, tags: newTags },
      { onSuccess: () => console.log('tag removed') }
    );
  };

  return (
    <ChatDetailsPage>
      <ChatMessagesContainer>
        {/*<ChatInfo>*/}
        {/*  <LeftColumn>*/}
        {/*    <ChatDetail><b>ChatID:</b> {chatId}</ChatDetail>*/}
        {/*    <ChatDetail><b>Username:</b> {chat?.username}</ChatDetail>*/}
        {/*    <ChatDetail><b>Full Name:</b> {chat?.fullName}</ChatDetail>*/}
        {/*    <ChatDetail><b>Last Message:</b> <DateLabel timestamp={chat?.lastMessageTime}/></ChatDetail>*/}
        {/*  </LeftColumn>*/}
        {/*  <RightColumn>*/}
        {/*    <ChatDetail><b>Active:</b> {chat?.active ? 'Yes' : 'No'}</ChatDetail>*/}
        {/*    <ChatDetail><b>Total Tokens:</b> {chat?.total_tokens}</ChatDetail>*/}
        {/*    <ChatDetail><b>Chat Status:</b> {chat?.chatStatus}</ChatDetail>*/}
        {/*  </RightColumn>*/}
        {/*</ChatInfo>*/}
        <ChatMessages chatId={chatId}/>
      </ChatMessagesContainer>

      {!isLoading && !error && (
        <ChatFooter>
          <ChatDetailsTagsList>
            <Tag variant="add" onAdd={() => setIsTagModalOpen(true)}>+ Add Tag</Tag>
            {chat?.tags?.map((tag) => (
              <Tag 
                key={tag}
                onRemove={() => handleRemoveTag(tag)}
              >{tag}</Tag>
            ))}
          </ChatDetailsTagsList>

          <ChatMessageInput chatId={chatId}/>
        </ChatFooter>
      )}

      <TagModal
        isOpen={isTagModalOpen}
        onClose={() => setIsTagModalOpen(false)}
        onAddTag={handleTagChange}
      />
      
    </ChatDetailsPage>
  );
}

const ChatDetailsPage = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: calc(100% - 10px);
  min-width: 320px;
`;

const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 8px;
`;

const ChatFooter = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 4px 16px;
`;

const ChatDetailsTagsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin: 2px;
`;

const ChatInfo = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const LeftColumn = styled(Column)``;
const RightColumn = styled(Column)``;

const ChatDetail = styled.div`
  margin-bottom: 8px;
  font-size: 1rem;

  & b {
    color: #1f2937;
  }
`;

export default ChatDetails;
