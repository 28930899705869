import styled from "styled-components";
import { Colors } from "../Styles/ColorPalette";

interface TagProps {
  children: React.ReactNode;
  variant?: 'default' | 'add' | 'inverted';
  onAdd?: () => void;
  onRemove?: () => void;
}

const variantStyles = {
  default: {
    background: Colors.tag.default.background,
    color: Colors.tag.default.text,
  },
  add: {
    background: Colors.tag.add.background,
    color: Colors.tag.add.text,
  },
  inverted: {
    background: Colors.tag.inverted.background,
    color: Colors.tag.inverted.text,
  },
};

const TagContainer = styled.button<{ $variant: TagProps['variant'] }>`
  background-color: ${props => variantStyles[props.$variant || 'default'].background};
  color: ${props => variantStyles[props.$variant || 'default'].color};
  font-size: 0.75rem;    
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  height: 28px;
`;

const RemoveButton = styled.span`
  margin-left: 6px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const Tag = ({ children, variant = 'default', onAdd, onRemove }: TagProps) => {
  return (
    <TagContainer $variant={variant} onClick={onAdd}>
      {children}
      {variant === 'default' && onRemove && (
        <RemoveButton onClick={(e) => { 
          e.stopPropagation(); 
          onRemove(); 
        }}>x</RemoveButton>
      )}
    </TagContainer>
  );
};

export default Tag;