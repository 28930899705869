let _baseUrl: string = '';
let _getToken: Function = () => {};

export const initialize = (baseUrl: string): void => {
  _baseUrl = baseUrl;
};

export const setTokenGetter = (getToken: Function): void => {
  _getToken = getToken;
}

export const post = <R, D>(endpoint: string, data: D): Promise<R> => {
  return fetchWrapper<R, D>(endpoint, 'POST', data);
};

export const get = <R>(endpoint: string): Promise<R> => {
  return fetchWrapper<R, null>(endpoint, 'GET');
}

export const put = <TResponse, TBody>(path: string, body: TBody): Promise<TResponse> => {
  return fetchWrapper<TResponse, TBody>(path, 'PUT', body);
};

const fetchWrapper = async <R, D> (url: string, method: string, data?: D): Promise<R> => {
  try {
    const response = await fetch(`${_baseUrl}/${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${(await _getToken())}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // TODO: handle logout
      }
      const errorData = await response.json();
      throw new Error(`Error1: ${response.status}: ${errorData?.error ?? 'Network Error'}` );
    }
    return await response.json() as R;
  }
  catch (err) {
    console.log('### NETWORK ERROR: ', err);
    throw err;
  }
}

