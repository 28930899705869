type ColorPalette = {
  background: {
    primary: string;
    chat: string;
    selection: string;
    chatMessage: {
      user: string;
      ai: string;
      agent: string;
    };
    banner: {
      default: string;
      success: string;
      warning: string;
      error: string;
    }
  };
  tag: {
    default: {
      background: string;
      text: string;
    };
    add: {
      background: string;
      text: string;
    };
    inverted: {
      background: string;
      text: string;
    };
  };
  button: {
    primary: ButtonColorSet;
    secondary: ButtonColorSet;
  };
  text: {
    primary: ColorSet;
    secondary: ColorSet;
    banner: string;
  };
  border: {
    primary: string;
  };
};

type ColorSet = {
  regular: string;
  selected: string;
  disabled?: string;
}

type ButtonColorSet = {
  background: ColorSet;
  text: ColorSet;
  border: ColorSet;
}
  
export const Colors: ColorPalette = {
  background: { 
    primary: 'white', // white
    chat: '#efede6', // alabaster
    selection: '#28a1cc', // light blue
    chatMessage: {
      user: 'white',
      ai: '#b9dfc0', // light green
      agent: '#f0dc97', // light yellow
    },
    banner: {
      default: '#38a8e0', // blue
      success: '#23ad5a', // green
      warning: '#c9c653', // yellow
      error: '#d45050', // red
    },
  },
  tag: {
    default: {
      background: '#cbe9f5',
      text: '#063e54',
    },
    add: {
      background: 'white',
      text: '#363636',
    },
    inverted: {
      background: 'white',
      text: '#4085a3',
    },
  },
  button: {
    primary: {
      background: {
        regular: '#d1603d',
        selected: 'white',
        disabled: '#bfbfbf',
      },
      text: {
        regular: 'white',
        selected: 'd1603d',
        disabled: 'white',
      },
      border: {
        regular: 'd1603d',
        selected: 'white',
        disabled: 'bfbfbf',
      },
    },
    secondary: {
      background: {
        regular: 'inherit',
          selected: 'inherit',
      },
      text: {
          regular: '#479a56', // dark green
          selected: 'white',
      },
      border: {
          regular: '#479a56', // dark green
          selected: 'white',
      },
    },
  },
  text: {
      primary: {
          regular: 'black',
          selected: 'white',
      },
      secondary: {
          regular: '#6b7280', // gray
          selected: 'white',
      },
      banner: 'white',
  },
  border: {
      primary: '#ededed', // light gray
  },
};