import styled, { css } from 'styled-components';
import { Colors } from '../Styles/ColorPalette';

type BannerVariant = 'default' | 'success' | 'warning' | 'error';

interface BannerProps {
  variant?: BannerVariant;
}

export const Banner = styled.div<BannerProps>`
  padding: 6px 16px;
  text-align: center;
  font-weight: 600;
  color: ${Colors.text.banner};

  ${props => {
    switch (props.variant) {
      case 'success':
        return css`
          background-color: ${Colors.background.banner.success};
        `;
      case 'warning':
        return css`
          background-color: ${Colors.background.banner.warning};
        `;
      case 'error':
        return css`
          background-color: ${Colors.background.banner.error};
        `;
      default:
        return css`
          background-color: ${Colors.background.banner.default};
        `;
    }
  }}
`;
