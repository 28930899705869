import * as Network from '../Infrastructure/Network';

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://vggzjjxkm2cf7igf6tbv545nni0dskab.lambda-url.eu-central-1.on.aws';

export const initialize = (): void => {
  console.log('✅ API_URL', API_URL);
  Network.initialize(API_URL);
};

export const setTokenGetter = (getToken: Function): void => {
  Network.setTokenGetter(getToken);
};

export const getChats = async (): Promise<Chat[]> => {
  return await Network.get<Chat[]>('chat');
};

export const getChatMessages = async (chatId: string): Promise<ChatMessage[]> => {
  return await Network.get<ChatMessage[]>(`chat/${chatId}/message`);
};

export const postChatMessage = async (chatId: string, message: string): Promise<ChatMessage> => {
  return await Network.post<ChatMessage, { message: string }>(`chat/${chatId}/message`, { message });
};

export const getChat = async (chatId: string): Promise<Chat> => {
  return await Network.get<Chat>(`chat/${chatId}`);
};

export const updateChat = (chatId: string, updates: Partial<Chat>): Promise<Chat> => {
  return Network.put<Chat, Partial<Chat>>(`chat/${chatId}`, updates);
};

export const activateAllChats = (): Promise<void> => {
  return Network.post<void, any>('chats/activate-all', {});
};
//   else if (path === '/chats/inactive-count' && httpMethod === 'GET') {

export const getInactiveChatsCount = (): Promise<number> => {
  return Network.get<number>('chats/inactive-count');
};

export const login = (username: string, password: string): Promise<any> => {
  return Network.post<any, any>(`login`, {
    username,
    password,
  });
};

// DTOs
export type Chat = {
  chatId: string;
  username: string;
  fullName: string;
  lastMessageTime: number;
  active: boolean;
  chatStatus?: string;
  total_tokens?: number;
  tags?: string[];
};

export type ChatMessage = {
  chatId: string;
  chatTitle: string;
  total_tokens: number;
  timestamp: number;
  message: string;
  firstNameLastName: string;
  username: string;
  photoUrl?: string;
};
