// src/components/ChatMessageInput.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import { usePostChatMessage } from "../../Domain/chats";
import { Colors } from '../Styles/ColorPalette';
import PrimaryButton from '../SharedComponents/PrimaryButton';

const ChatMessageInput = ({ chatId }: { chatId: string }) => {
  const [message, setMessage] = useState('');

  const { mutate: postMessage, isPending, error } = usePostChatMessage();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    if (message.trim()) {
      console.log('Sending message:', message);

      try {
        const res = postMessage({ chatId, message }, {
          onSuccess: () => {
            setMessage('');
          }
        });
      } catch (err) {
        console.error('Error sending message:', err);
      }
    }
  };

  return (
    <InputContainer>
      {error && <div>Error: {error?.message}</div>}
      <StyledTextField
        value={message}
        onChange={handleChange}
        placeholder="Type a message..."
        variant="outlined"
        multiline
        // rows={1}
        minRows={1} // Starts with 1 row and expands as needed
        maxRows={8} // Maximum of 4 rows
        fullWidth
        margin="dense"
        size="small"
        InputProps={{
          endAdornment: (
            <PrimaryButton 
              disabled={isPending || !message.trim()} 
              onClick={handleSend}>
              Send
            </PrimaryButton>
          ),
        }}
      />
    </InputContainer>
  );
};

export default ChatMessageInput;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SendButton = styled(Button)`
  margin-left: 8px;
`;

const StyledTextField = styled(TextField)`
  background-color: ${Colors.background.primary};
  border-radius: 8px;
`;