import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, TextField, Box } from '@mui/material';
import { Tag } from '../SharedComponents/Tag';
import PrimaryButton from '../SharedComponents/PrimaryButton';
import { useAllUniqueTags } from '../../Domain/chats';
import { Colors } from '../Styles/ColorPalette';

interface TagModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddTag: (tag: string) => void;
}

export const TagModal = ({
  isOpen,
  onClose,
  onAddTag,
}: TagModalProps) => {
  const [newTagName, setNewTagName] = useState('');
  const allTags = useAllUniqueTags();

  const handleAddTag = (tag: string) => {
    onAddTag(tag);
    setNewTagName(''); // Reset input after adding
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <TagsModalBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: Colors.background.primary,
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <h3>Add New Tag</h3>
        <TextField
          autoFocus
          fullWidth
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
          margin="normal"
          label="Tag Name"
        />

        <PrimaryButton onClick={() => handleAddTag(newTagName)}> Add Tag </PrimaryButton>

        {allTags.length > 0 && (
            <h4>Or Select Existing Tag:</h4>
        )}
        <ChatDetailsTagsList> 
          {allTags.map((tag) => (
            <Tag 
              key={tag}
              onAdd={() => handleAddTag(tag)}
            >{tag}</Tag>
          ))}
        </ChatDetailsTagsList>
          
      </TagsModalBox>
    </Modal>
  );
};

const TagsModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 2px;
`;

const ChatDetailsTagsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin: 2px;
`;